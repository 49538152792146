import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AppState, AppStateStatus } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { Spinner, View } from 'native-base';
import { Locale } from 'locale-enum';
import { find } from 'lodash';
import moment from 'moment';

import { useAppLocale } from '@pimm/common';
import { GetEmployees } from '@pimm/services/lib/sms-workforce/services';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { StoreFocusState } from '@app/store/state';
import { storeFocusSlice } from '@app/store/slices/storeFocusSlice';
import { selectStoreFocus, selectUserProfile } from '@app/store/selectors';
import thunks from '@app/store/thunks';
import { useSiteConfig } from '@app/features/store-core';
import { useAppGlobalsGoals, useAuthToken, useUsersWithSiteKeys } from '@app/features/app';
import { useGetUsersWithSiteKeys } from '@app/features/store-info/hooks';
import { RootScreenProps } from '@app/navigations/root';

// This is a higher-order component that is responsible for running all required tasks before rendering the package
export const packageBuilder = <P extends object>(ScreenComponent: React.ComponentType<P>) => {
  return (props: P) => {
    const { navigation } = props as RootScreenProps<any>;
    const route = useRoute();
    const params = route.params as { siteId?: string };

    const queryClient = useQueryClient();
    const queryCache = queryClient.getQueryCache();

    const dispatch = useAppDispatch();
    const { locale, setLocale, translate } = useAppLocale();
    const { setSiteConfig } = useSiteConfig();
    const { authorization, sessionTimeout, user, refreshAuthorization } = useAuthToken();
    const storeFocus = useAppSelector(state => selectStoreFocus(state));
    const userProfile = useAppSelector(state => selectUserProfile(state));
    const queryUsersWithSiteKeys = useGetUsersWithSiteKeys({ siteId: storeFocus.data.store?.id });

    const [appState, setAppState] = useState<AppStateStatus>(AppState.currentState);

    const culture = storeFocus.data?.store?.culture;

    useEffect(() => {
      if (queryUsersWithSiteKeys.data) {
        useUsersWithSiteKeys.getState().setUsers(queryUsersWithSiteKeys.data);
      }
    }, [queryUsersWithSiteKeys.data]);

    useEffect(() => {
      if (userProfile?.userId) {
        const _site =
          find(userProfile.sites, _ => _.id === params?.siteId) ?? find(userProfile.sites, _ => _.id === userProfile?.defaultSiteId);

        // This is to prevent page reload
        if (storeFocus.status === 'fulfilled' && storeFocus.data.store?.id === _site?.id) return;

        // Make sure to clear any previous store data
        dispatch(storeFocusSlice.actions.clear());
        useUsersWithSiteKeys.getState().clear();

        dispatch(thunks.changeStoreFocus(_site?.id)).then(async result => {
          const { store } = result.payload as StoreFocusState;
          // Get store data defaults
          if (store) {
            useAppGlobalsGoals.getState().setWeekday(store.config?.storeHoursConfig?.firstDayOfWeek);
            useAppGlobalsGoals.getState().setUtcOffset(store.address?.utcOffsetInMinutes);
          }
          // Register site config
          setSiteConfig(store ?? { id: _site?.id, name: _site?.name });

          // Update route params
          navigation.setParams({ siteId: store?.id ?? _site?.id });

          // On change store, let's clear the cache entirely and start fresh
          queryCache.clear();

          const employees = await GetEmployees({ siteId: store?.id });
          dispatch(storeFocusSlice.actions.setEmployees(employees ?? []));
        });
      }
    }, [userProfile?.userId, params?.siteId]);

    useEffect(() => {
      if (!locale && culture) setLocale(culture as Locale);
    }, [locale, culture]);

    useEffect(() => {
      if (authorization) {
        if (authorization.status === 'Authorized') {
          // Fetch user default data
          dispatch(thunks.getUserProfile(user!.userId));
          // Get default event types
          if (user?.brandId) {
            dispatch(thunks.getThresholdProfiles(user.brandId));
          }
          return;
        }

        // Expired: redirect to login screen
        navigation.navigate('Login');
      }
    }, [authorization]);

    // TODO: create a reusable function
    useEffect(() => {
      let timer;
      if (authorization?.data && authorization.status === 'Authorized') {
        const diff = moment(authorization.data.expires_in).diff(new Date(), 'minutes');
        if (appState === 'active' && diff > 0) {
          // Refresh token before expiration, but make sure that the app state is currently active
          // create delay if the remaining minutes is more than 10 or rather force refresh
          timer = diff > 10 ? setTimeout(refreshAuthorization, (diff - 5) * 60 * 1000) : refreshAuthorization();
        }
        // Prompt session timeout dialog message
        if (diff <= 0) sessionTimeout.setTrue();
      }
      // clear timer to ensure that no refresh will happen if ever the app state is in the background
      return () => clearTimeout(timer);
    }, [authorization, appState]);

    useEffect(() => {
      const subscription = AppState.addEventListener('change', setAppState);

      if (authorization?.data && authorization.status === 'Authorized') {
        const diff = moment(authorization.data.expires_in).diff(new Date(), 'minutes');
        // Refresh the token as soon as possible after the screen is mounted but
        // prevent refreshing token after login or it mus be less than 25mins before the expiration time
        if (diff > 0 && diff < 25) refreshAuthorization();
      }
      return () => {
        subscription.remove();
      };
    }, []);

    if (storeFocus.status === 'idle' || storeFocus.status === 'pending') {
      return (
        <View h="full" alignItems="center" justifyContent="center">
          <Spinner size={28} color="gray.300" />
        </View>
      );
    }

    return <ScreenComponent {...props} />;
  };
};
